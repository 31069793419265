import "@okta/okta-signin-widget/css/okta-sign-in.min.css";
import "@components/okta/custom-okta-widget-clean-room.css";

import { AppLayout } from "@components/layout/AppLayout";
import type { AppProps } from "next/app";
import { AsciiBanner } from "@components/AsciiBanner";
import { AuthSecurity } from "../lib/auth/AuthSecurity";
import PersistentLeftDrawerLayout from "@components/sidemenu/PersistentLeftDrawerLayout";
import { ScriptAdobeLaunch } from "@components/ScriptAdobeLaunch";
import { ScriptDatadogRum } from "@components/ScriptDatadogRum";
import { SiteHead } from "@components/SiteHead";
import { ThemeProvider } from "@customerexperience/foundation";
import { appWithTranslation } from "next-i18next";
import { useBuild } from "@components/useBuild";
import { useRouter } from "next/router";
import { useUncaughtErrorHandler } from "../lib/uncaught-error-handler";

const App = (props: AppProps) => {
  useUncaughtErrorHandler();
  useBuild();

  return (
    <>
      <SiteHead />
      <AsciiBanner />

      <ThemeProvider>
        <AuthSecurity>
          <RouteAwareLayout {...props} />
        </AuthSecurity>
      </ThemeProvider>

      <ScriptAdobeLaunch />
      <ScriptDatadogRum />
    </>
  );
};

function RouteAwareLayout(props: AppProps) {
  const { Component, pageProps } = props;
  const router = useRouter();

  // Check if the current route matches `unauth-home`
  const isUnauthHome = router.pathname.startsWith("/unauth-home");

  // Conditionally apply different layouts based on the route
  if (isUnauthHome) {
    // Use PersistentLeftDrawerLayout for unauth-home routes
    return (
      <PersistentLeftDrawerLayout>
        <Component {...pageProps} />
      </PersistentLeftDrawerLayout>
    );
  }

  // Default to the AppLayout for all other routes
  return (
    <AppLayout>
      <Component {...pageProps} />
    </AppLayout>
  );
}

export default appWithTranslation(App);
