"use client"

import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  TextField,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircularProgress from "@mui/material/CircularProgress";
import { DynamicRecaptcha } from "@components/recaptcha/DynamicRecaptcha";
import { ErrorBox } from "@components/request-access/support/ErrorBox";
import Image from "next/image";
import InputLabel from "@mui/material/InputLabel";
import { getContactFormEndpoint } from "@components/request-access/request-access-api";
import { prefixWithBasePath } from "../../lib/asset";
import { useCustomerContactFormik } from "./useCustomerContactFormik";
import { useSiteKey } from "@components/recaptcha/useSiteKey";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface CustomDrawerProps {
  open: boolean;
  handleDrawerClose: () => void;
  drawerWidth: number;
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  backgroundColor: "#000",
  justifyContent: "space-between;",
}));

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  open,
  handleDrawerClose,
  drawerWidth,
}) => {
  const { i18n, t } = useTranslation();

  const theme = useTheme();

  const sitekey = useSiteKey();

  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useCustomerContactFormik(() => {
    handleSubmit();
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = { ...formik.values };

    console.info("🛫 sending customer contact form payload", payload);
    const result = await postRequest(payload);
    setIsLoading(false);
    setSubmitted(true);


    if (result) {
      console.info("submit request successful");
      return true;
    }

    console.info("submit request failure");
    return false;
  };
  
  async function postRequest(payload = {}) {
    const postEndpoint = getContactFormEndpoint();
    const response = await fetch(postEndpoint, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" },
      referrerPolicy: "no-referrer",
      body: JSON.stringify(payload),
    });
    const result = response.status >= 200 && response.status <= 204;
    return result;
  }
  /**
   * Set the reCAPTCHA token
   */
  function setRecaptchaToken(token: any) {
    if (token && token.length > 0) {
      formik.setFieldValue("accessToken", token);
    } else {
      formik.setFieldValue("accessToken", "");
    }
  }

  const clearRecaptchaToken = () => {
    formik.setFieldValue("accessToken", "");
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <Image
          src={prefixWithBasePath("/assets/images/r-white.png")}
          alt={"Cargill Logo"}
          height={36}
          width={72}
        />

        <IconButton
          onClick={handleDrawerClose}
          sx={{
            backgroundColor: "#fff", // Optional: Add a background color for the button
            "&:hover": {
              backgroundColor: "#f0f0f0", // Optional: Add a hover background color
            },
          }}
        >
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>

      <Divider sx={{ paddingBottom: 5 }} />

      <Box
        sx={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "48px",
        }}
      >
        <Typography
          variant="h1"
          sx={{ color: "text.primary", fontWeight: "700", fontSize: "44px" }}
        >
          {t("welcome:portals_side_menu.title")}
        </Typography>
        <Typography
          sx={{ color: "text.primary", fontSize: "16px", lineHeight: "137.5%" }}
        >
          {t("welcome:portals_side_menu.description")}
        </Typography>
      </Box>

      {/* Formik Form inside the Drawer */}
      <Box sx={{ padding: "16px" }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Contact Form
        </Typography>
        <Box sx={{ marginBottom: "0px", padding: "0 24px" }}>
          <form onSubmit={formik.handleSubmit}>
            {/* First Name */}
            <InputLabel
              sx={{ textAlign: "left", mt: "1rem" }}
              htmlFor="firstName"
            >
              First Name
            </InputLabel>
            <TextField
              fullWidth
              id="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.firstName && formik.touched.firstName && (
              <ErrorBox>{formik.errors.firstName}</ErrorBox>
            )}

            {/* Last Name */}
            <InputLabel
              sx={{ textAlign: "left", mt: "1rem" }}
              htmlFor="lastName"
            >
              Last Name
            </InputLabel>
            <TextField
              fullWidth
              id="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.lastName && formik.touched.lastName && (
              <ErrorBox>{formik.errors.lastName}</ErrorBox>
            )}

            {/* Company Name */}
            <InputLabel
              sx={{ textAlign: "left", mt: "1rem" }}
              htmlFor="companyName"
            >
              Company Name (Optional)
            </InputLabel>
            <TextField
              fullWidth
              id="companyName"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.companyName && formik.touched.companyName && (
              <ErrorBox>{formik.errors.companyName}</ErrorBox>
            )}

            {/* Email */}
            <InputLabel sx={{ textAlign: "left", mt: "1rem" }} htmlFor="email">
              Email
            </InputLabel>
            <TextField
              fullWidth
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.email && formik.touched.email && (
              <ErrorBox>{formik.errors.email}</ErrorBox>
            )}

            {/* reCAPTCHA */}
            <Box
              sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 2 }}
            >
              <DynamicRecaptcha
                sitekey={sitekey}
                language={i18n.language}
                onChange={setRecaptchaToken}
                onExpired={clearRecaptchaToken}
              />
            </Box>
            <Box mt={12}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button sx={{ width: "100%", mb: 4 }} type="submit">
                  Submit
                </Button>
              )}
  
            </Box>
          </form>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: "10px",
              lineHeight: "137.5%",
            }}
          >
            {t("welcome:portals_side_menu.policy_advice")}
          </Typography>
        </Box>
      </Box>

      <Divider />
      {/* Additional Drawer Content (Optional) */}
      <List>{/* You can place additional list items here */}</List>
    </Drawer>
  );
};

export default CustomDrawer;
