import dynamic from "next/dynamic";
import { PropsWithChildren } from "react";

export interface Props extends PropsWithChildren {}

/**
 * Safely loads the segmented Auth Emitter.
 */
export const DynamicAuthEmitter = dynamic(
  () => import("./AuthEmitter").then((module) => module.AuthEmitter),
  { ssr: false }
);
