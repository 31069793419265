/**
 * Retrieves the request access countries endpoint.
 * @returns The request access countries endpoint.
 * @throws Error if the environment variable is not set.
 */
export function getRequestAccessCountriesEndpoint(): string {
  const endpoint = process.env.NEXT_PUBLIC_REQUEST_ACCESS_COUNTRIES_ENDPOINT;
  if (!endpoint) {
    throw new Error("Request access countries endpoint not set");
  }
  return endpoint;
}

/**
 * Retrieves the request access endpoint.
 * @returns The request access endpoint.
 * @throws Error if the environment variable is not set.
 */
export function getRequestAccessEndpoint(): string {
  const endpoint = process.env.NEXT_PUBLIC_REQUEST_ACCESS_ENDPOINT;
  if (!endpoint) {
    throw new Error("Request access endpoint not set");
  }
  return endpoint;
}

/**
 * Retrieves the contact form endpoint.
 * @returns The contact form endpoint.
 * @throws Error if the environment variable is not set.
 */
export function getContactFormEndpoint(): string {
  const endpoint = process.env.NEXT_PUBLIC_CONTACT_FORM_ENDPOINT;
  if (!endpoint) {
    throw new Error("Contact form endpoint not set");
  }
  return endpoint;
}
