import * as yup from "yup";

import { CustomerContactData } from "./softauth-model";
import { useFormik } from "formik";
import { useTranslation } from "next-i18next";

const validateRestrictedEmails = (
  email: string,
  restrictedEmails: string[]
) => {
  try {
    const index = email.lastIndexOf("@");
    if (index > -1 && restrictedEmails.includes(email.slice(index))) {
      return false;
    }
    return true;
  } catch (error) {
    return true;
  }
};

/**
 * Manages form data for customer data step.
 * @param onNext a call back that signals submission
 * @returns a formik instance
 */
export function useCustomerContactFormik(
  onNext: (data: CustomerContactData) => void
) {
  const { t } = useTranslation();
  const messageRequired = t("common:common.required");
  const invalidDomain = t("common:common.invalid_domain");
  const messageTextOnly = t("common:common.text_only");
  const messageEmployeeEmail = t(
    "request_access:customer_access.customer_email_error"
  );

  const expressionTextOnly = /^([^0-9@&*"']*)$/;
  const expressionCustomerEmail =
    /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,})$/i;

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(messageRequired)
      .matches(expressionTextOnly, messageTextOnly),
      lastName: yup
      .string()
      .required(messageRequired)
      .matches(expressionTextOnly, messageTextOnly),
      companyName: yup
      .string()
      .matches(expressionTextOnly, messageTextOnly),
      accessToken: yup
      .string()
      .required(messageRequired),
      email: yup
      .string()
      .test("test", invalidDomain, function (value) {
        const { restrictedEmailDomains } = this.parent;
        return validateRestrictedEmails(value + "", restrictedEmailDomains);
      })
      .required(messageRequired)
      .matches(expressionCustomerEmail, messageEmployeeEmail),
      restrictedEmailDomains: yup.array().of(yup.string())
  });

  const formik = useFormik<CustomerContactData>({
    initialValues: {
      firstName: "",
      lastName: "",
      companyName:'',    
      email: "",
      accessToken: "",
      appRefName: "",
      restrictedEmailDomains: [],
      businessRegion: []
    },
    validationSchema: validationSchema,
    // use handleSubmission instead
    onSubmit: (values) => {
      onNext(values);
    },
  });

  return formik;
}