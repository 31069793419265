import {
  TopNav,
  LanguageSelector,
  LSMenuItem,
} from "@customerexperience/foundation";
import { getCookie, setCookie } from "cookies-next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import { prefixWithBasePath } from "../../lib/asset";
import { languages } from "../../lib/language/language";
import Image from "next/image";
import { Button } from "@mui/material";

export function AppHeader() {
  const { t } = useTranslation("common");

  const router = useRouter();
  const { pathname, asPath, query, locale } = router;

  const languageCookie = getCookie("NEXT_LOCALE") || (locale as string);

  const [currentLanguage, setCurrentLanguage] = useState(
    languages.find((language) => language.code === languageCookie)
      ?.name as string
  );
  const [currentLangIndex, setCurrentLangIndex] = useState(
    languages.findIndex((language) => language.code === languageCookie)
  );

  const changeLanguage = (index: number): void => {
    const { name, code } = languages[index];
    setCurrentLanguage(name);
    setCurrentLangIndex(index);
    setCookie("NEXT_LOCALE", code);
    router.push({ pathname, query }, asPath, {
      locale: code,
    });
  };

  return (
    <>
      <TopNav
        logo={
          <Link
            href="/"
            style={{ display: "block" }}
            data-testid="cargill-icon"
          >
            <Image
              src={prefixWithBasePath("/assets/images/cargill-logo.svg")}
              // 182 x 83
              alt="logo"
              width={70}
              height={32}
              priority
            />
          </Link>
        }
      >
        {pathname.includes("landing") ? (
          <>
            <Link href="/logout" passHref legacyBehavior>
              <Button variant="outlined" sx={{ color: "white" }}>
                {t("common:common.sign_out")}
              </Button>
            </Link>
          </>
        ) : (
          <>
            <Link href="/login" passHref legacyBehavior>
              <Button variant="outlined" sx={{ color: "white" }}>
                {t("common:header.login")}
              </Button>
            </Link>
            <Link href="/request-access" passHref legacyBehavior>
              <Button variant="contained" data-testid="main-nav-get-access">
                {t("common:header.get_access")}
              </Button>
            </Link>
          </>
        )}

        <LanguageSelector currentLanguage={currentLanguage}>
          {languages.map((language, index) => {
            const { name, code } = language;
            return (
              <LSMenuItem
                key={code}
                selected={index === currentLangIndex}
                divider
                value={code}
                onClick={() => changeLanguage(index)}
              >
                {name}
              </LSMenuItem>
            );
          })}
        </LanguageSelector>
      </TopNav>
    </>
  );
}
