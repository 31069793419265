import Box from "@mui/material/Box";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode | string | undefined;
}

export function ErrorBox({ children }: Props) {
  if (!children) {
    return null;
  }

  return (
    <Box
      sx={{
        color: "#cd0d15",
        fontSize: "12px",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      {children}
    </Box>
  );
}
