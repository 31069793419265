import * as React from 'react';

import { AppFooter } from '@components/layout/AppFooter';
import CustomAppBar from './CustomAppBar';
import CustomDrawer from './CustomDrawer';
import MainContent from './MainContent';

const drawerWidth = 500;

interface PersistentDrawerLeftProps {
  children: React.ReactNode;
}

export default function PersistentLeftDrawerLayout({ children }: PersistentDrawerLeftProps) {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomAppBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <CustomDrawer open={open} handleDrawerClose={handleDrawerClose} drawerWidth={drawerWidth} />
      <MainContent open={open} drawerWidth={drawerWidth}>
        {children}
      </MainContent>
      <AppFooter />

    </>
  );
}
