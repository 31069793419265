/**
 * Prefixes a given path with the next base path configuration property.
 *
 * For more details on the base path configuration:
 * @see ./next.config.js
 *
 * For more details on how base path works:
 * @see https://nextjs.org/docs/api-reference/next.config.js/basepath
 *
 * 🧠 the base path should only be applied in `live` environments (dev, qa, int,
 * stage, prod) but not when running locally or when running directly on a
 * subdomain
 *
 * 💡 use next/link Link when possible, because it handles this base path
 * adaption automatically
 *
 * @param path
 * @returns a path prefixed if possible
 */
export function prefixWithBasePath(path: string) {
  const basePath = process.env.BASE_PATH;
  if (!basePath) {
    return path;
  }

  return `${basePath}${path}`;
}
