import Script from "next/script";

/**
 * Datadog RUM Script.
 *
 * Datadog RUM (real user monitoring) gathers client side performance metrics.
 *
 * 🖐️ This does not reuse the previous mycargill.com or mycargill.com/home RUM configurations.
 *
 * @see https://docs.datadoghq.com/real_user_monitoring/
 *
 * @see https://nextjs.org/docs/basic-features/script
 *
 * @returns
 */
export function ScriptDatadogRum() {
  const env = process.env.BUILD_ENV || "local";
  const number = process.env.BUILD_NUMBER || "local";
  return (
    <>
      {process.env.NEXT_PUBLIC_DATADOG_RUM_SCRIPT_ENABLED === "true" ? (
        <Script id="datadog-rum" async>
          {`(function(h,o,u,n,d) {
          h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
          d=o.createElement(u);d.async=1;d.src=n
          n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
        })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js','DD_RUM')
        window.DD_RUM.onReady(function() {
          window.DD_RUM.init({
            clientToken: 'pubfe4022bfb8ef1f221cc040f437867a2e',
            applicationId: 'ae35d4ac-f7f9-4d77-a769-e5d419e71148',
            site: 'datadoghq.com',
            service: 'mycargill.com/home',
            env: '${env}',
            // Specify a version number to identify the deployed version of your application in Datadog 
            version: '${number}',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
          });
      
          window.DD_RUM.startSessionReplayRecording();
        })`}
        </Script>
      ) : (
        <noscript data-dog-disabled></noscript>
      )}
    </>
  );
}
