import Box from "@mui/material/Box";
import { PropsWithChildren } from "react";
import { AppFooter } from "./AppFooter";
import { AppHeader } from "./AppHeader";

export interface LayoutProps extends PropsWithChildren {}

export function AppLayout({ children }: LayoutProps) {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "min-content 1fr min-content",
      }}
    >
      {/* header */}
      <AppHeader />

      {/* main */}
      <Box
        component={`main`}
        sx={{ display: "flex", flexDirection: "column" }}
        data-main
      >
        {children}
      </Box>

      {/* footer */}
      <AppFooter />
    </Box>
  );
}
