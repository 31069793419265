import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { styled } from "@mui/material/styles";

interface FloatingButtonProps {
  open: boolean; // Now required
  handleDrawerOpen?: () => void;
}

const FloatingButtonContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: theme.spacing(18), // 16px from the top
  left: theme.spacing(2), // 16px from the left
  zIndex: 1300, // Ensures it's above most other elements
}));

const FloatingButton: React.FC<FloatingButtonProps> = ({
  open,
  handleDrawerOpen,
}) => {
  return (
    <FloatingButtonContainer sx={{ display: open ? "none" : "block" }}>
      <IconButton
        color="primary"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        sx={{
          backgroundColor: "#000",
          boxShadow: (theme) => theme.shadows[3],
          "&:hover": {
            backgroundColor: (theme) => theme.palette.primary.light,
          },
        }}
      >
        {/* <Button onClick={toggleDrawer(true)} aria-label="Open drawer" sx={{ position: "fixed", backgroundColor: "#000" }}> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M15.25 5H10.75L15.75 12L10.75 19H15.25L20.25 12L15.25 5Z"
            fill="white"
          />
          <path
            d="M8.25 5H3.75L8.75 12L3.75 19H8.25L13.25 12L8.25 5Z"
            fill="white"
          />
        </svg>
      </IconButton>
    </FloatingButtonContainer>
  );
};

export default FloatingButton;
