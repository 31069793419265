// based on code from:
// https://github.com/okta/okta-react/blob/6.2/src/OktaContext.ts

import { AuthState, OktaAuth } from "@okta/okta-auth-js";
import React, { useContext } from "react";

/**
 * AuthCtx.
 *
 * Represents the Okta Auth React context.
 *
 * Minor tweaks:
 * * name
 * * oktaAuth can be null
 *
 */
export interface AuthCtx {
  oktaAuth: OktaAuth | null;
  authState: AuthState | null;
  authenticated: boolean | null;
}

export const AuthContext = React.createContext<AuthCtx | null>(null);

/**
 * Gets the AuthCtx from the Auth Context.
 * @returns see AuthCtx
 */
export function useAuthContext() {
  return useContext(AuthContext);
}
