import Script from "next/script";

/**
 * Adobe Analytics Script.
 *
 * Setup performed by Arlem Brenes. 2023-02-20.
 *
 * @see https://nextjs.org/docs/basic-features/script
 *
 * @returns
 */
export function ScriptAdobeLaunch() {
  return (
    <>
      {process.env.NEXT_PUBLIC_ADOBE_LAUNCH_SCRIPT_URL && (
        <Script src={process.env.NEXT_PUBLIC_ADOBE_LAUNCH_SCRIPT_URL} async strategy="lazyOnload" />
      )}
    </>
  );
}
