/**
 * Languages we support.
 *
 * This array of languages is shared among the browser, server and middleware bundles.
 */
export const languages: Languages = [
  { code: "id", name: "Bahasa" },
  { code: "bg", name: "Български" },
  { code: "nl", name: "Nederlands" },
  { code: "en", name: "English" },
  { code: "es", name: "Español" },
  { code: "fr", name: "Français" },
  { code: "de", name: "Deutsch" },
  { code: "hu", name: "Magyar" },
  { code: "it", name: "Italiano" },
  { code: "ja", name: "日本語" },
  { code: "ko", name: "한국어" },
  { code: "nb", name: "Norsk" },
  { code: "pl", name: "Polski" },
  { code: "pt", name: "Português" },
  { code: "ro", name: "Romana" },
  { code: "ru", name: "Русский" },
  { code: "tr", name: "Türk" },
  { code: "uk", name: "Yкраїнська" },
  { code: "vi", name: "Tiếng Việt" },
  { code: "zh", name: "正體字/繁體字" },
];

export const fallback = { code: "en", name: "English" };

export type Languages = Array<{
  code: string;
  name: string;
}>;
