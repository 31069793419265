import { PropsWithChildren, useCallback, useState } from "react";
import { AuthContext, AuthCtx } from "./AuthContext";
import { DynamicAuthEmitter } from "./DynamicAuthEmitter";

export interface AuthSecurityProps extends PropsWithChildren {}

/**
 * This is an SSR/SSG interoperable lazily loaded component that passes through children,
 * while listening for additional updates from Okta.
 *
 * We cannot load `<AuthEmitter />` because it or its dependencies (Okta) assume that `window` exists.
 * `window` does not exist during SSR/SSG.
 *
 * @param param0 props, children are required
 * @returns a component that passes through children
 */
export function AuthSecurity({ children }: AuthSecurityProps) {
  const [state, setState] = useState<AuthCtx | null>({
    oktaAuth: null,
    authenticated: null,
    authState: null,
  });

  const subscriber = useCallback(
    (ctx: AuthCtx) => {
      setState(ctx);
    },
    [setState]
  );

  return (
    <>
      <DynamicAuthEmitter subscriber={subscriber} />
      <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
    </>
  );
}
