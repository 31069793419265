import { useEffect } from "react";
import { OAuthError } from "@okta/okta-auth-js";

/**
 * Hook. Handles specific uncaught errors.
 *
 */
export function useUncaughtErrorHandler() {
  useEffect(() => {
    function handleUncaughtError(event: PromiseRejectionEvent) {
      if (
        event.reason &&
        event.reason instanceof OAuthError &&
        "message" in event.reason &&
        event.reason.message.includes(
          "The client specified not to prompt, but the user is not logged in."
        )
      ) {
        event.stopPropagation();
        event.preventDefault();
        console.warn(
          `🔏 the okta session expired and could not automatically renew; catching error`
        );
      }
    }

    if (typeof window !== "undefined") {
      window.addEventListener("unhandledrejection", handleUncaughtError);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("unhandledrejection", handleUncaughtError);
      }
    };
  }, []);
}
