export function AsciiBanner() {
  // eslint-disable-next-line
  const banner = `
          <!--
  
          ███    ███ ██    ██  ██████  █████  ██████   ██████  ██ ██      ██          ██████  ██████  ███    ███     ██ ██   ██  ██████  ███    ███ ███████ 
          ████  ████  ██  ██  ██      ██   ██ ██   ██ ██       ██ ██      ██         ██      ██    ██ ████  ████    ██  ██   ██ ██    ██ ████  ████ ██      
          ██ ████ ██   ████   ██      ███████ ██████  ██   ███ ██ ██      ██         ██      ██    ██ ██ ████ ██   ██   ███████ ██    ██ ██ ████ ██ █████   
          ██  ██  ██    ██    ██      ██   ██ ██   ██ ██    ██ ██ ██      ██         ██      ██    ██ ██  ██  ██  ██    ██   ██ ██    ██ ██  ██  ██ ██      
          ██      ██    ██     ██████ ██   ██ ██   ██  ██████  ██ ███████ ███████ ██  ██████  ██████  ██      ██ ██     ██   ██  ██████  ██      ██ ███████ 
                                                                                                                                                            
                                                                                                                                                                                                                                   
          Crafted in ${new Date().getFullYear()}.
          -->
      `;
  return <noscript dangerouslySetInnerHTML={{ __html: banner }} />;
}
