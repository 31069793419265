import Head from "next/head";
import { prefixWithBasePath } from "../lib/asset";

export function SiteHead() {
  return (
    <>
      <Head>
        <title>myCargill</title>

        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <SiteOpenGraph />

        <SiteFavicon />
      </Head>
    </>
  );
}

function SiteOpenGraph() {
  return (
    <>
      <meta
        name="description"
        content="myCargill is your convenient source for essential information and support for your business. Wherever you are, we're here for you every step of the way."
      />
      <meta
        name="keywords"
        content="Cargill, MyCargill, Portal, Customer, Agriculture, Animal Nutrition, Beauty, Bioindustrial, Food & Beverage, Foodservice, Industrial, Protein"
      />

      <meta
        property="og:description"
        content="myCargill is your convenient source for essential information and support for your business. Wherever you are, we're here for you every step of the way."
      />
      <meta property="og:title" content="myCargill" />
      <meta property="og:type" content="website" />

      <meta
        property="og:image"
        content={prefixWithBasePath(
          "/assets/images/mycargill-open-graph-banner.png"
        )}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content="myCargill" />

      {/* todo: dynamically set this based on deployment environment */}
      <meta property="og:url" content="https://www.mycargill.com" />

      {/* todo: dynamically set this by current locale */}
      <meta property="og:locale" content="en_US" />
    </>
  );
}

function SiteFavicon() {
  return (
    <>
      {/* metatags and file variants generated with https://realfavicongenerator.net/ */}

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={prefixWithBasePath("/assets/favicon/apple-touch-icon.png?v=18")}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={prefixWithBasePath("/assets/favicon/favicon-32x32.png?v=18")}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={prefixWithBasePath("/assets/favicon/favicon-16x16.png?v=18")}
      />
      <link
        rel="mask-icon"
        href={prefixWithBasePath("/assets/favicon/safari-pinned-tab.svg?v=18")}
        color="#6e8c32"
      />
      <link
        rel="shortcut icon"
        href={prefixWithBasePath("/assets/favicon/favicon.ico?v=18")}
      />
      <meta name="msapplication-TileColor" content="#2b5797" />
      <meta
        name="msapplication-config"
        content={prefixWithBasePath("/assets/favicon/browserconfig.xml?v=18")}
      />
      <meta name="theme-color" content="#ffffff" />

      <SiteManifest />
    </>
  );
}

/**
 * SiteManifest.
 *
 * This component will toggle between two variants of the same file, with `/home` set, or not. This is detected based on if the base path is supplied during build time.
 * @returns
 */
function SiteManifest() {
  // obviously this is a hack because it effectively hardcodes `/home` but the
  // value of this is fairly low and it's unlikely that this deployment will
  // live anywhere other than root (where this is moot) or `/home` where this is
  // tuned to work properly the burden of course is for those that have to ever
  // update that manifest file

  const hasPrefix = prefixWithBasePath("/").includes("/home");
  return (
    <>
      {hasPrefix ? (
        <link
          rel="manifest"
          href={prefixWithBasePath(
            "/assets/favicon/site.home.webmanifest?v=18"
          )}
          crossOrigin="use-credentials"
        />
      ) : (
        <link
          rel="manifest"
          href={prefixWithBasePath("/assets/favicon/site.webmanifest?v=18")}
          crossOrigin="use-credentials"
        />
      )}
    </>
  );
}
