import { useMemo } from "react";

export function useSiteKey() {
  const sitekey = useMemo(() => {
    const sitekey = process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY;

    if (!sitekey) {
      console.warn("recaptcha sitekey was not set");
      throw new Error("recaptcha form validation failed");
    }

    return sitekey;
  }, []);

  return sitekey;
}
