import { styled } from "@mui/material/styles";

interface MainContentProps {
  open: boolean;
  drawerWidth: number;
  children: React.ReactNode;
}

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth",
})<MainContentProps>(({ theme, open, drawerWidth }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MainContent: React.FC<MainContentProps> = ({
  open,
  drawerWidth,
  children,
}) => {
  return (
    <Main open={open} drawerWidth={drawerWidth}>
      {children}
    </Main>
  );
};

export default MainContent;
