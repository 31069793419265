import { useEffect } from "react";

/**
 * Adds build information to window if available.
 *
 */
export function useBuild() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      // @ts-ignore
      window.__MYCARGILL_HOME_BUILD__ =
        process.env.BUILT === "true"
          ? {
              BUILD_CREATED: process.env.BUILD_CREATED,
              BUILD_COMMIT: process.env.BUILD_COMMIT,
              BUILD_NUMBER: process.env.BUILD_NUMBER,
              BUILD_ENV: process.env.BUILD_ENV,
            }
          : null;
    }
  }, []);
}
