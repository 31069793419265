import CircularProgress from "@mui/material/CircularProgress";
import dynamic from "next/dynamic";

export const DynamicRecaptcha = dynamic(
  () =>
    import("./Recaptcha").then(async (module) => {
      // simulating loading time
      //   await delay(5 * 60 * 1000);
      return module.Recaptcha;
    }),
  {
    ssr: false,
    loading: () => {
      return (
        <>
          <CircularProgress />
        </>
      );
    },
  }
);
